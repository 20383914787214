/* OrgAdminRoutingMixin.ts */

import { Component, Mixins } from 'vue-property-decorator';
import { AppHostnameMixin, RoutingMixin } from "@/mixins";
import { Route } from 'vue-router';
import * as Routes from '@/../types/constants/org-admin.routes';
import { isEmpty } from '@/pipes';
import { AthleteProfileModel } from '@/models';

/**
 * Provides routing routines
 */
@Component
export class OrgAdminRoutingMixin extends Mixins(RoutingMixin, AppHostnameMixin) {
	get RouteToTeamCreate(): Partial<Route> {
		return this.getRoute( Routes.TeamCreate );
	}

	RouteToOrganization(organizationId: string, route: string = Routes.OrgAdminClubDashboard): Partial<Route> {
		return {
			name: route,
			params:{ organizationId }
		}
	}

	RouteToOrganizations(): Partial<Route> {
		return { name: Routes.OrgsAdminDashboard };
	}
	RouteToOrgDashboard(organizationId: string): Partial<Route> {
		return this.RouteToOrganization(organizationId);
	}
	RouteToOrgSettings(organizationId: string): Partial<Route> {
		return this.RouteToOrganization(organizationId, Routes.OrgAdminSettings);
	}
	RouteToOrgAthletes(organizationId: string): Partial<Route> {
		return this.RouteToOrganization(organizationId, Routes.OrgAdminManagePlayers);
	}
	RouteToOrgStaff(organizationId: string): Partial<Route> {
		return this.RouteToOrganization(organizationId, Routes.OrgAdminManageStaff);
	}
	RouteToOrgLandingPage(organizationId: string): Partial<Route> {
		return this.RouteToOrganization(organizationId, Routes.OrgAdminManageLandingPage);
	}
	RouteToTeam(teamId: string): Partial<Route> {
		return 	{ 
			name: Routes.TeamDashboard,
			params:{ teamId }
		}
	}
	AthletePublicSharingUrl(athleteProfile: Partial<AthleteProfileModel>): string {
        if( isEmpty(athleteProfile) ) return '';
        if( isEmpty(athleteProfile.sharingUrlId) ) return `https://${this.PublicAppUrl}/athlete/${athleteProfile.id}`;
		return `https://${this.PublicAppUrl}/athlete/${athleteProfile.id}/${athleteProfile.sharingUrlId}`
	}

    gotoAthleteProfile(athleteProfile: Partial<AthleteProfileModel>) {
        if( isEmpty(athleteProfile) ) return;
        this.gotoUrl(this.AthletePublicSharingUrl(athleteProfile))
    }


	gotoOrgsDashboard() {
		this.gotoRoute(this.RouteToOrganizations())
	}

	async gotoOrganization(organizationId: string) {
		await this.gotoRoute(this.RouteToOrgDashboard(organizationId));
	}
	gotoOrgSettings(organizationId: string) {
		this.gotoRoute(this.RouteToOrgSettings(organizationId));
	}
	
	gotoTeamCreate() {
		this.gotoRoute(this.RouteToTeamCreate);
	}
	gotoOrgTeam(teamId: string) {
		this.gotoRoute(this.RouteToTeam(teamId));
	}

	gotoOrgAthletes(organizationId: string) {
		this.gotoRoute(this.RouteToOrgAthletes(organizationId));
	}
	gotoOrgStaff(organizationId: string) {
		this.gotoRoute(this.RouteToOrgStaff(organizationId));
	}

	gotoOrgLandingPage(organizationId: string) {
		this.gotoRoute(this.RouteToOrgLandingPage(organizationId));
	}
}
