// ColorsMixin.ts

import { Component, Vue } from 'vue-property-decorator';

@Component
export class ColorsMixin extends Vue {
    moreColors: boolean = false;
    baseColors = [
        [
            '#FFFFFF', // white
            '#000000', // black
            '#FF0000', // red
            '#00FF00', // green
            '#0000FF', // blue
        ],
        [
            '#FFD800', // yellow
            '#B200FF', // purple
            '#00FFFF', // cyan
            '#FF6A00', // orange
            '#FF00DC', // pink
        ],
    ]
    
    teamColors = [
        [
            '#004d98', // Barcelona Blau
            '#6CABDD', // Manchester City
            '#0097DB', // Zenit
            '#1e71b8', // Atalanta
            '#034694', // Chelsea
        ],
        [
            '#00428c', // Porto
            '#010E80', // Inter Milan
            '#004170', // PSG
            '#272e61', // Atletico
            '#132257', // Tottenham
        ],
        [
            '#a50044', // Barcelona Grana
            '#fb090b', // AC Milan
            '#f43333', // Sevilla
            '#EF0107', // Arsenal
            '#E83030', // Benfica
        ],
        [
            '#DA291C', // Manchester United
            '#D2122E', // Ajax
            '#c8102E', // Liverpool
            '#B81137', // Toronto FC
            '#a90432', // Galatasaray
        ],
        [
            '#65B32E', // Wolfsburg
            '#5D9741', // Seattle
            '#018749', // Celtic
            '#FFF200', // Norwich
            '#FDE100', // Dortmund
        ],
        [
            '#F0612C', // Shaktar
            '#f7b5cd', // Miami
            '#633492', // Orlando
            '#E4E5E6', // Minnesota
            '#241F20', // Newcastle
        ],
    ];

    transpose<T>(matrix: T[][]): T[][] {
        return matrix[0].map((_, colIndex) => matrix.map(row => row[colIndex]));
    }
   get ColorSwatches(): Array<Array<string>> {
        if( this.moreColors ) return undefined; // show all colors
        return this.transpose(this.baseColors.concat(this.teamColors));
    }
}