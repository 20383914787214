
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, StringsMixin, BAIconsMixin, OrgResolverMixin, OrgAdminRoutingMixin } from '@/mixins';

@Component
export default class OrgAdminLinksCard extends Mixins(VuetifyMixin, StringsMixin, BAIconsMixin, OrgResolverMixin, OrgAdminRoutingMixin) {
	// organizationId is inherited from OrgResolverMixin
	// @Prop({ required: true }) organizationId: string;

	@Prop({type: Boolean}) loaded;
	@Prop({type: Boolean}) noDashboard;
	@Prop({type: Boolean}) noAthletes;
	@Prop({type: Boolean}) noStaff;
	@Prop({type: Boolean}) noSettings;
}
