
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { AppHostnameMixin, AuthMixin, StringsMixin, BAIconsMixin, VuetifyMixin, RoutingMixin, AthleteRoutingMixin } from '@/mixins';
import OnboardingDialog from '@/views/onboarding/OnboardingDialog.vue';
import { OrganizationModel, OrganizationLandingPage, OrgField } from '@/models/organization/OrganizationModel';
import { organizationApi } from '@/api/OrganizationApi';
import { AthleteProfileModel, PlayerOnOrg } from '@/models';
import { athleteApi } from '@/api/AthleteApi';

@Component({
	components: {
		Page,
        OnboardingDialog,
	}
})
export default class BAOrgLandingPage extends Mixins(VuetifyMixin, AppHostnameMixin, StringsMixin, AuthMixin, BAIconsMixin, RoutingMixin, AthleteRoutingMixin) {
    get organizationId(): string {
        return this.$route.params.organizationId;
    }

    organization: OrganizationModel;
    isReady: boolean = false;
    async mounted() {
        if( this.$route.path.includes('public') ) {
            if( this.IsLoggedIn ) {
                this.gotoRoute(this.RouteToOrganization(this.organizationId));
                return;
            }
        }

        this.isReady = false;
        this.organization = await organizationApi.publicFindById(this.organizationId);
        if( this.IsNotEmpty(this.organization) ) {
            await this.prepareNewMember();
            await this.populateForm();
        }
        this.isReady = this.IsNotEmpty(this.organization);
    }

    newMember: PlayerOnOrg;
    isMemberUpdated: boolean = false;
    async prepareNewMember() {
        if( this.IsEmpty(this.LandingPage) ) return;

        this.newMember = new PlayerOnOrg;
        const fields = this.organization.landingPage.fields;
        this.newMember.info = fields.map(f => { return {key: f.key, value: ''} });
        this.isMemberUpdated = true;
    }
    async populateForm() {
        if( !this.IsAthlete ) return;

        const athlete: AthleteProfileModel = await athleteApi.findById(this.AthleteId);
        if( this.IsEmpty(athlete) ) return;

        const skipKeys = ['id'];
        for( const info of this.newMember.info ) {
            for( const key in athlete ) {
                if( skipKeys.find(s => s === key ) ) continue;
                if( key === 'birthDate') {
                    if( this.KeyRepresents(info.key, 'birth') ) { 
                        info.value = this.formatDatePretty(new Date(athlete[key]));
                        continue;
                    }
                }
                if( this.KeyRepresents(info.key, key) ) {
                    if( this.KeyRepresents(info.key, 'date') ) { 
                        info.value = this.formatDatePretty(new Date(athlete[key]));
                    } else {
                        info.value = athlete[key];
                    }
                }
            }
        }
        this.newMember.athleteId = athlete.id;
    }

    get IsReady(): boolean {
        return this.isReady;
    }
    get HasOrganization(): boolean {
        return( this.IsNotEmpty(this.organization) );
    }
    get HasLandingPage(): boolean {
        if( !this.HasOrganization ) return false;
        if( this.IsEmpty(this.organization.landingPage) ) return false;
        return this.IsNotEmpty(this.organization.landingPage.title);
    }
    get LandingPage(): OrganizationLandingPage {
        if( !this.HasLandingPage ) return undefined;
        return this.organization.landingPage;
    }

    async onChangeValue(field: OrgField, value: any) {
        if( field.key.toLowerCase().includes('email') ) {
            const athlete = athleteApi.findByEmail(this.newMember.email);
            if( this.IsNotEmpty(athlete) ) {}
        }
    }
    async onSubmitForm() {
        const orgMember = this.organization.members.find(m => (( m.athleteId === this.newMember.athleteId ) && ( this.IsNotEmpty(m.athleteId) ) ||  ( m.email === this.newMember.email ) && ( this.IsNotEmpty(m.email) )) );
        if( this.IsNotEmpty(orgMember) ) {
            alert('This athlete is already a member of this organization');
            return;
        }

        this.isMemberUpdated = false;
        const infoEmail = this.newMember.info.find(i => i.key.toLowerCase().includes('email'));
        if( this.IsNotEmpty(infoEmail) ) this.newMember.email = infoEmail.value;
        const infoFirstName = this.newMember.info.find(i => this.NormalizeString(i.key).includes('firstname'));
        if( this.IsNotEmpty(infoFirstName) ) this.newMember.firstName = infoFirstName.value;
        const infoLastName = this.newMember.info.find(i => this.NormalizeString(i.key).includes('lastname'));
        if( this.IsNotEmpty(infoLastName) ) this.newMember.lastName = infoLastName.value;
        this.isMemberUpdated = true;

        this.organization.members.push(this.newMember);
        organizationApi.patchPublic({id: this.organization.id, members: this.organization.members});
    }
}
