import { Component, Mixins, Prop } from 'vue-property-decorator';
import { OrganizationModel, OrganizationLandingPage } from '@/models/organization/OrganizationModel';
import { orgAdminStore } from '@/store';
import { TeamModel } from '@/models';
import { StringsMixin } from './StringsMixin';

async function resolveOrganizationFromUrl(to, __, next): Promise <void> {
	const { organizationId } = to.params;
	if(organizationId) {
		orgAdminStore.loadOrganizationById({ organizationId });
	}
	next();
}

@Component({
	beforeRouteEnter: resolveOrganizationFromUrl,
	beforeRouteUpdate: resolveOrganizationFromUrl,
})
export class OrgResolverMixin extends Mixins(StringsMixin) {
	@Prop({ required: true }) organizationId: string;

	async loadCurrentOrganization(): Promise<void> {
		if( !this.HasCurrentOrganization ) return;
		await orgAdminStore.loadOrganizationById({organizationId: this.organizationId});
		await this.organizationLoadComplete();
	}
	async organizationLoadComplete() {
		// override this function to act after organization is loaded
		if( this.IsEmpty(this.CurrentOrganization.landingPage) ) {
			this.CurrentOrganization.landingPage = new OrganizationLandingPage;
		}
		if( this.IsEmpty(this.CurrentOrganization.landingPage.title) ) {
			this.CurrentOrganization.landingPage.title = this.CurrentOrganization.name;
			this.CurrentOrganization.landingPage.bannerUrl = this.CurrentOrganization.logoUrl;
			this.CurrentOrganization.landingPage.info = this.CurrentOrganization.Location;
		}
	}
	async loadOrganizations() {
		await orgAdminStore.loadOrganizations();
	}
	async loadOrgTeams(organizationId: string) {
		if( this.IsEmpty(organizationId) ) return;
		await orgAdminStore.loadOrganizationTeams({organizationId});
	}

	get HasCurrentOrganization(): boolean {
		return this.IsNotEmpty(this.organizationId);
	}
	get IsOrgsLoaded(): boolean{
		return orgAdminStore.organizationsInitialized;
	}
	get IsOrganizationLoaded(): boolean {
		return orgAdminStore.organizationLoaded;
	}
	get IsOrgTeamsLoaded(): boolean {
		return orgAdminStore.loadOrganizationTeamsInitialized;
	}
	get IsOrganizationPatching(): boolean {
		return orgAdminStore.patchOrganizationLoading;
	}
	get CurrentOrganizationLoading(): boolean{
		return orgAdminStore.loadOrganizationByIdLoading;
	}

	get CurrentOrganization(): OrganizationModel {
		if( !this.IsOrganizationLoaded ) return undefined;
		return orgAdminStore.organizations.find(o => o.id === this.organizationId);
	}
	get Organizations(): OrganizationModel[]{
		return orgAdminStore.organizations;
	}
	get OrgTeams(): Array<TeamModel> {
		return orgAdminStore.teamList;
	}

	get HasCurrentOrgLandingPage(): boolean {
		if( !this.IsOrganizationLoaded ) return false;
		return( this.IsNotEmpty(this.CurrentOrganization.landingPage) );
	}
	get CurrentOrgLandingPage(): OrganizationLandingPage {
		if( !this.HasCurrentOrgLandingPage ) return undefined;
		return this.CurrentOrganization.landingPage;
	}
	SaveCurrentOrganization() {
		if( !this.IsOrganizationLoaded ) return;
		orgAdminStore.patchOrganization(this.CurrentOrganization);
	}	
}
