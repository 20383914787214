
import { Component, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, MyAthleteMixin, UpgradeNowMixin, AthleteRoutingMixin,BAIconsMixin, MindsetMixin } from '@/mixins';
import AthleteMindsetReportCard from "@/components/profile/athlete/AthleteMindsetReportCard.vue"

@Component({
	components:{
		AthleteMindsetReportCard,
	},
})
export default class AthleteMindsetReport extends Mixins(VuetifyMixin, MyAthleteMixin, UpgradeNowMixin, AthleteRoutingMixin, BAIconsMixin, MindsetMixin) {

	created() {
		this.userId = this.TheAthleteId;
	}

	get HasMindsetReportFeature() {
		if( !this.IsSubscriptionsLoaded ) return false;
		if( !this.HasUserPaidSubscriptions ) return false;

		for( const sub of this.UserPaidSubscriptions ) {
			if( sub.plan.featureMap.MINDSET_REPORT ) return true;
		}

		return false;
	}
}
