
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { VuetifyMixin, StringsMixin, BAIconsMixin, OrgResolverMixin, OrgAdminRoutingMixin, PaginatedTableMixin, AthleteApiMixin } from '@/mixins';
import { DataTableHeader } from 'vuetify';
import { PlayerOnOrgEx, PlayerOnTeam } from '@/models';
import { athleteApi } from '@/api/AthleteApi';

@Component
export default class OrgAdminLinksCard extends Mixins(VuetifyMixin, StringsMixin, BAIconsMixin, OrgResolverMixin, OrgAdminRoutingMixin, PaginatedTableMixin, AthleteApiMixin) {
    search: string = '';
    localForagePersistFields: Array<string | [string, any]> = [['search', ''],['tableOptions.page', 1],['tableOptions.itemsPerPage', 10]];
       
    @Watch('organizationId') async onChangeOrganizationId() {
        await this.loadCurrentOrganization();
        this.tableOptions.sortBy = ['firstName', 'lastName'];
        this.tableOptions.sortDesc = [false, false];
        await this.loadAthletes();
    }
    async mounted() {
        await this.onChangeOrganizationId();
    }

    membersEx: Array<PlayerOnOrgEx> = [];
    async loadAthletes() {
        this.membersEx = await Promise.all(
            this.CurrentOrganization.members.map(async(m) => {
                const memberEx: PlayerOnOrgEx = new PlayerOnOrgEx(m);
                memberEx.athlete = await athleteApi.getPublicProfile(memberEx.athleteId);
                const teamPlayer: PlayerOnTeam = memberEx.athlete?.CurrentTeam.players.find(p => p.athleteId === memberEx.athleteId);
                memberEx.number = teamPlayer?.number;
                return memberEx;
        }));
    }
    async loadTable() {
    }
	get TableLoading(): boolean {
		return !this.IsOrganizationLoaded;
	}
    get PageLoading(): boolean {
		return !this.TableLoading;
	}
	get TotalItems(): number {
        if( !this.IsOrganizationLoaded ) return 0;
		return this.membersEx.length;
	}
    get TableHeaders(): Array<DataTableHeader<any>> {
        let headers: Array<DataTableHeader<any>> = [ 
            { text: 'Name', value: 'firstName', sortable: false },
            { text: 'Team', value: 'team', sortable: false },
            { text: 'No.', value: 'number', sortable: false },
            // { text: 'Gender', value: 'gender', sortable: false },
            { text: 'Email', value: 'email', sortable: false },
        ];
        if( this.IsLargeScreen ) {
            headers.push({text: '', value: 'actions', sortable: false });
            headers.push({text: '', value: 'data-table-expand', sortable: false});
        }
        return headers;
    }

    async onClickAthlete(player: PlayerOnOrgEx) {
        this.gotoAthleteProfile(player.athlete);
    }
    async onClickTeam(player: PlayerOnOrgEx) {
        if( !player?.athlete?.HasTeam ) return;
        this.gotoOrgTeam(player.athlete.CurrentTeam.id);
    }
}
