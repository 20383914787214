
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiPencil } from '@mdi/js';
import Page from './Page.vue';
import CoachAwardSvg from '../components/svg/CoachAwardSvg.vue'
import CoachChampSvg from '../components/svg/CoachChampSvg.vue'
import CoachExpSvg from '../components/svg/CoachExpSvg.vue'
import ContextMenu from '../components/ui/ContextMenu.vue'
import EditOverlay from '../components/ui/EditOverlay.vue'
import ImageUploadDialog from '../components/profile/ImageUploadDialog.vue'
import TeamMiniCard from '../components/ui/TeamMiniCard.vue'
import WinsTiesLosses from '../components/ui/WinsTiesLosses.vue'

import { coachProfile, imagesStore} from '../store';
import { CoachProfileModel } from '../models/coach/CoachProfileModel';
import { AuthMixin, MyTeamsMixin, MyCoachMixin, BAIconsMixin, StringsMixin } from '@/mixins';
import { TeamModel } from '../models/team';
import { country } from '../pipes/country.pipe';
import { StaffRole } from '@/../types/enums';
import { CoachResumeModel } from '@/models/coach/CoachResumeModel';
import { ContextMenuItem } from '@/../types/interfaces';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { formatDateMonthYear } from "@/helpers/date";

@Component({
	components: {
		CoachAwardSvg,
		CoachChampSvg,
		CoachExpSvg,
		ContextMenu,
		EditOverlay,
		ImageUploadDialog,
		Page,
		TeamMiniCard,
		WinsTiesLosses,
	},
})
export default class CoachProfile extends Mixins(BAIconsMixin, AuthMixin, MyCoachMixin, MyTeamsMixin, StringsMixin){
	country = country;
	showImageUploadDialog: boolean = false;
	mdiPencil = mdiPencil;

	formatDateMonthYear = formatDateMonthYear;

	@Prop() coachId: string;


	get IsMyCoachProfile(): boolean{
		return this.coachId === this.MyCoachId;
	}

	get CoachTeams(): Array<TeamModel> {
		return this.MyTeamsSorted(this.MyCoachProfile?.teams);
	}
	get CoachProfilePhoneNumber(): string | null{
		if(!coachProfile.coachProfile) return null;
		if(!coachProfile.coachProfile.phoneNumber) return null;
		return coachProfile.coachProfile.phoneNumber;
	}
	get CoachResumeIsSetup(): boolean{
		return coachProfile.coachProfile.resume !== undefined;
	}
	get CoachResume(): CoachResumeModel {
		return coachProfile.coachProfile.resume;
	}
	async created(): Promise<void>{
		await coachProfile.getCoachProfile(this.coachId)
	}

	get Loading(): boolean{
		return coachProfile.loading;
	}

	get CoachProfilePictureUrl(): string{
		if(!coachProfile.coachProfile) return "";
		return coachProfile.coachProfile.pictureUrl;
	}

	async setProfilePhoto(pictureUrl: string): Promise<void> {
		imagesStore.setCache({id: this.MyCoachId, url: pictureUrl});
		await coachProfile.setCoachProfilePicture({ coachId: this.MyCoachId, pictureUrl: pictureUrl });
	}

	get CoachFullName(): string{
		return this.MyCoachProfile.firstName + " " + this.MyCoachProfile.lastName
	}

	getRole(team: TeamModel, coachId: string): string{
		const staff = team.staffMembers.find(s => s.coachId === coachId);
		if(staff == undefined) return "";
		switch(staff.role){
		case StaffRole.Admin:
			return "Administrator";
		case StaffRole.HeadCoach:
			return "Head Coach";
		case StaffRole.AssistantCoach:
			return "Assistant Coach";
		default:
			return "Staff";
		}
	}

	goToEditResume(step?: number): void{
		this.$router.push({
			path: '/c/settings',
			query: { u: this.CurrentUserProfileId }
		});
	}

	gotoTeam(teamId: string) {
		this.$router.push({
			name: Routes.TeamDashboard,
			params:{
				teamId,
			}			
		})
	}

	contextMenuItems:  ContextMenuItem[] = [
		{
			icon: mdiPencil,
			text: 'Edit Profile',
			click: (): void => {
				this.goToEditResume();
			}
		},
	];

}
