
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '@/mixins';
import { TeamModel } from '@/models/team';
import SoccerCardSvg from '@/components/svg/teamCards/SoccerCardSvg.vue';
import { Gender, SportName } from '@/../types/enums';

@Component({
	components: {
		SoccerCardSvg,
	}
})
export default class OrgTeamCard extends Mixins(VuetifyMixin, BAIconsMixin){
	@Prop() private color: string;
	@Prop({ required: true }) team: TeamModel;

	get Color(): string{
		if(this.color) return this.getColor(this.color);
		if(this.team.gender === Gender.Male) return this.getColor('baColorDeepBlue');
		if(this.team.gender === Gender.Female) return this.getColor('baColorLightBlue3');
		if(this.team.gender === Gender.Coed) return this.getColor('baColorLightBlue3');
		return this.getColor('baColorDeepBlue');
	}
	get SportIconComponent(): string{
		if(this.team?.sportId === SportName.Soccer) return 'SoccerCardSvg';
		return '';
	}
	get SvgColor(): string{
		if(this.team.gender === Gender.Female) return 'baColorSportCardIconDark';
		if(this.team.gender === Gender.Coed) return 'baColorSportCardIconDark';
		return 'baColorSportCardIconLight';
	}

	get PlayerCount(): number{
		return this.team.players.length;
	}
}
