
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue'
import { StringsMixin, BAIconsMixin, MyCoachMixin, MyTeamsMixin, CoachRoutingMixin, VuetifyMixin } from '@/mixins';
import TeamMiniCard from '@/components/ui/TeamMiniCard.vue';
import LoadingWait from '@/components/ui/LoadingWait.vue';
import { TeamModel } from '@/models';

@Component({
	components:{
		Page,
        TeamMiniCard,
	},
})
export default class CoachTeamsDashboard extends Mixins(VuetifyMixin, StringsMixin, MyCoachMixin, MyTeamsMixin, BAIconsMixin, CoachRoutingMixin) {
    get Loading(): boolean{
		return !this.MyCoachIsReady;
	}

	get CoachTeams(): Array<TeamModel> {
		return this.MyTeamsSorted(this.MyCoachProfile?.teams);
	}

	isTeamsUpdating: boolean = false;
	async onClickMove(from: number, to: number) {
		this.isTeamsUpdating = true;
		if( this.IsEmpty(this.MyCoachProfile.teams) ) await this.UpdateCoachTeams(this.MyTeams);

		await this.moveCoachTeam(from, to);
		this.isTeamsUpdating = false;
	}
}

