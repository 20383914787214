// TeamModel.ts

import { PlayerOnTeam } from './PlayerOnTeam';
import { StaffMember } from './StaffMember';
import { AccessControlledModel } from '../AccessControlledModel';
import { StaffRole } from '@/../types/enums/staff-role';
import { DepthChartModel } from '../depthChart';
import { grantUserAcls } from '@/services/AccessControlledService';
import { TeamPermission } from '@/../types/permissions/team-permissions';
import { TeamWTLInfo } from '@/../types/interfaces';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { capitalize, isEmpty, isNotEmpty, title } from '@/pipes';
import { SportName } from '@/../types/enums';

export class TeamJersey {
	label: string;
	color: string;
	accent: string;
	number: string;
}

export class TeamModel extends AccessControlledModel{
	sharingUrlId: string = "";
	name: string = "";
	country: string = "";
	city: string = "";
	gender: string = "";
  	organizations?: OrganizationModel[] = [];
	organizationName: string = "";
	organizationLocation: string = "";
	organizationId: string = "";
	organizationTypeId: string = "";
	organizationTypes: string = "";
	sportId: SportName = SportName.Soccer;
	seasonFormat: string = "year";
	seasonYear: number = new Date().getFullYear();
	logoUrl: string = "";
	staffMembers: StaffMember[] = [];
	players: PlayerOnTeam[] = [];
	depthCharts: DepthChartModel[] = [];
	wtlSummary: TeamWTLInfo | null = null;
	division: string = "";
	bio: string = "";
	teamUrl: string = "";
	honors: string = "";
	notable: string = "";
	archive: boolean = false;
	teamCalendarURL: string="";
	jerseyPrimary: TeamJersey = {
		label: 'Primary',
		color: '#FF0000FF',
		accent: "#FFFFFFFF",
		number: "#FFFFFFFF",
	};
	jerseyAlternate: TeamJersey = {
		label: 'Alternate',
		color: '#0000BBFF',
		accent: "#FFFFFFFF",
		number: "#FFFFFFFF",
	};
	jerseyOther: TeamJersey = {
		label: 'Goalkeeper',
		color: '#007F0EFF',
		accent: "#FFFFFFFF",
		number: "#FFFFFFFF",
	};

	get HasCalendarURL(): boolean {
		return isNotEmpty(this.CalendarURL);
	}
	get CalendarURL(): string {
		return this.teamCalendarURL;
	}
	setStaffMember(userId: string, staffMember: StaffMember): void{
		// figure out default role
		// if (staffRole === undefined) {
		// 	userRelation.role = AthleteProfileModel.Roles.find(role => role.name === userRelation.relationship.defaultRole);
		// }
		if (staffMember.role !== undefined) {
			// handle different staff members
			// revokeUserAcls(this, TeamPermissionValues, { userId: staffMember.id });
			grantUserAcls(this, [TeamPermission.InviteAthlete], { userId });
		}

		const userIndex = this.staffMembers.findIndex(u => u.id === staffMember.id);
		if(userIndex > -1){
			this.staffMembers.splice(userIndex, 1, staffMember);
		}else{
			this.staffMembers.push(staffMember);
		}
	}

	get Gender(): string {
		return capitalize(this.gender);
	}
	get HeadCoach(): StaffMember | null {
		const headCoach = this.staffMembers.find(staff => staff.role === StaffRole.HeadCoach);
		return headCoach?? null;
	}
	get HeadCoachName(): string {
		const coach = this.HeadCoach;
		if( !coach ) return 'not assigned';

		if( isNotEmpty(coach.firstName) ) {
			if( isNotEmpty(coach.lastName) ) return `${coach.firstName} ${coach.lastName}`;
			else return coach.firstName;
		} else if( isNotEmpty(coach.lastName) ) return coach.lastName;
		return '';
	}

	get AssistantCoaches(): StaffMember[] | null {
		const assisstants = this.staffMembers.filter(staff => staff.role === StaffRole.AssistantCoach);
		return assisstants.length > 0? assisstants : null;
	}

	get AdminCoaches(): StaffMember[] | null {
		const admin = this.staffMembers.filter(staff => staff.role === StaffRole.Admin);
		return admin.length > 0? admin : null;
	}

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		this.depthCharts = this.depthCharts.map(dc => new DepthChartModel().load(dc));
		this.players = this.players.map(p => {
			if( isEmpty(p.number)) p.number = '';
			return p;
		})
		return this;
	}

	get Sport(): SportName {
		if( isEmpty(this.sportId) ) return SportName.Soccer;
		return SportName[this.sportId as keyof typeof SportName];
	}

	get NumPlayers(): number {
		return this.players.length;
	}
	get NumAthletes(): number {
		return this.players.filter(p => isNotEmpty(p.athleteId)).length;
	}
	get Location(): string {
		if( isNotEmpty(this.city) ) {
			if( isNotEmpty(this.country) ) return `${this.city}, ${this.country}`;
			else return this.city;			
		}
		else if( isNotEmpty(this.country) ) return this.country;

		return '';
	}

	get FullBio(): string{
		return `${this.name} - ${this.Location}`
	}

	get DetailsTitle(): string {
		let gender: string;
		switch (this.gender.toLowerCase()){
		case 'male':
			gender = "Mens";
			break;
		case 'female':
			gender = "Womens";
			break;
		default:
			gender = "Coed";
			break;
		}
		const city = this.Location !== null ? ` | ${this.Location}` : '';
		return `${gender} ${title(this.sportId)}${city}`;
	}

	// team record
	get W(): string {
		if( !this.wtlSummary ) return '0';
		return this.wtlSummary.W.toString();
	}
	get D(): string {
		if( !this.wtlSummary ) return '0';
		return this.wtlSummary.T.toString();
	}
	get L(): string {
		if( !this.wtlSummary ) return '0';
		return this.wtlSummary.L.toString();
	}

	get WTLSummary(): string {
		return `${this.wtlSummary.W} - ${this.wtlSummary.T} - ${this.wtlSummary.L}`;
	}
}
