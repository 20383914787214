
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { PageState } from '@/models/PageState';
import { PaginatedTableMixin, VuetifyMixin, BAIconsMixin } from '@/mixins';
import { DataTableHeader } from 'vuetify';
import { adminStore } from '@/store';
import * as AdminRoutes from '@/../types/constants/admin.routes';
import * as OrgAdminRoutes from '@/../types/constants/org-admin.routes';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { organizationApi } from '@/api/OrganizationApi';
import { notificationStore } from '@/store';
import { NotificationModel } from '@/models/notification/NotificationModel';
import { NotificationUIType } from '@/../types/enums';

@Component({
	components: {
		Page,
	}
})
export default class OrganizationAdminDashboard extends Mixins(VuetifyMixin, BAIconsMixin, PaginatedTableMixin){
	pageState: PageState = new PageState('Initial');
	search: string = '';

	mounted() {
		this.tableOptions.sortBy = ['name'];
		this.tableOptions.itemsPerPage = 10;
	}

	onViewOrg(org: OrganizationModel): void{
		this.$router.push({
			name: OrgAdminRoutes.OrgAdminClubDashboard,
			params:{
				organizationId: org.id,
			}
		});
	}
	createOrg(): void{
		this.$router.push({
			name: AdminRoutes.OrgCreate,
		});
	}
	editOrg(id: string): void{
		this.$router.push({
			name: AdminRoutes.OrgEdit,
			params:{
				editOrganizationId: id,
			}
		});
	}

	async syncOrgAthletes(id: string): Promise<void>{
		await organizationApi.reIndexAthletes({ organizationId: id });
		notificationStore.pushLocalNotification(new NotificationModel().load({
			uiType: NotificationUIType.Banner,
			message: 'Organization Players queued to sync in the background'
		}))
	}

	get TableHeaders(): Array<DataTableHeader<any>> {
		let headers: Array<DataTableHeader<any>> = [
			{ text: 'Organization', value: 'name', sortable: false },
			{ text: 'Location', value: 'city', sortable: false },
			{ text: 'Sport', value: 'sport', sortable: false },
			{ text: 'No. Teams', value: 'TeamCount', sortable: false },
			{ text: 'Type', value: 'type', sortable: false },
			{ text: 'Account Status', value: 'AccountStatus', sortable: false },
		];
		if( this.IsLargeScreen ) {
			headers.push({text: '', value: 'actions', sortable: false });
			headers.push({text: '', value: 'data-table-expand', sortable: false});
		}
		return headers;
	}
	async loadTable(): Promise<void>{
		try {
			await adminStore.loadOrganizationList({ query: this.TableQuery<OrganizationModel>(this.search, ['name', 'city', 'league', 'id']), options: this.TableQueryOptions });
		} catch(e) {
			notificationStore.pushSnackbarError({message: `Error loading athletes: ${e}`});
		} finally {
			this.pageState = new PageState('Ready');
		}
	}
	get PageLoading(): boolean {
		return this.TableLoading;
	}
	get TableLoading(): boolean {
		return adminStore.organizationsLoading;
	}
	get TotalItems(): number {
		return adminStore.totalOrganizations;
	}
	get Organizations(): OrganizationModel[]{
		return adminStore.organizationList;
	}
	get ActiveOrganizations(): number{
		return adminStore.activeOrganizations;
	}
}
