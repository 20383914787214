
import Page from '@/views/Page.vue';
import { Component, Mixins } from 'vue-property-decorator';
import { mdiTrashCanOutline } from '@mdi/js';
import { orgAdminStore } from '@/store';
import { DataTableHeader } from 'vuetify';
import { VuetifyMixin, MindsetDisplayMixin, OrgResolverMixin } from '@/mixins';
import OrgCard from '@/components/orgAdmin/OrgCard.vue';
import OrgAdminLinksCard from './OrgAdminLinksCard.vue';
import { StaffMember } from '@/models/team/StaffMember';
import { ContextMenuItem } from '@/../types/interfaces';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog.vue';
import OrganizationCreateStaffInfo from '@/components/admin/OrganizationCreateStaffInfo.vue';
import { OrganizationModel } from '@/models/organization/OrganizationModel';

@Component({
	components: {
		Page,
		ConfirmationDialog,
		OrgCard,
		OrgAdminLinksCard,
		OrganizationCreateStaffInfo,
	}
})
export default class OrgAdminManageStaff extends Mixins(OrgResolverMixin, VuetifyMixin, MindsetDisplayMixin){
	created(): void{
		this.loadOrgStaff();
	}

	get TableLoading(): boolean{
		return !orgAdminStore.loadOrganizationStaffInitialized || orgAdminStore.loadOrganizationStaffLoading;
	}

	async loadOrgStaff(): Promise<void>{
		await this.loadCurrentOrganization();
		return orgAdminStore.loadOrganizationStaff({organizationId: this.organizationId});
	}

	getStaffItems(staff: StaffMember): ContextMenuItem[]{
		const items: ContextMenuItem[] = [
			{
				text: 'Remove',
				icon: mdiTrashCanOutline,
				click: () => this.confirmDeleteStaff(staff),
			}
		]
		return items;
	}

	showConfirmDeleteStaff: boolean = false;
	selectedDeleteStaffMember: StaffMember | null = null;
	get ConfirmDeleteText(): string{
		if(this.selectedDeleteStaffMember === null) return "";
		return `Remove "${this.selectedDeleteStaffMember.firstName} ${this.selectedDeleteStaffMember.lastName}" from ${this.CurrentOrganization.name}?`; 
	}
	confirmDeleteStaff(staff: StaffMember): void{
		this.selectedDeleteStaffMember = staff;
		this.showConfirmDeleteStaff = true;
	}
	get RemoveStaffLoading(): boolean{
		return orgAdminStore.removeStaffLoading;
	}
	async deleteStaff(staff: StaffMember){
		await orgAdminStore.removeStaff({ 
			organizationId: this.organizationId,
			staffId: staff.id
		});
		this.showConfirmDeleteStaff = false;
		this.selectedDeleteStaffMember = null;
	}

	showConfirmAddStaff: boolean = false;
	addStaffFormValue = this.resetAddStaffForm();
	private resetAddStaffForm(){
		return this.addStaffFormValue = {
			valid: false,
			staffMembers: [],
			sendStaffInvitations: true,
		};
	}
	confirmAddStaff(): void{
		this.resetAddStaffForm();
		this.showConfirmAddStaff = true;
	}
	closeAddStaff(): void{
		this.showConfirmAddStaff = false;
		this.resetAddStaffForm();
	}
	get AddStaffLoading(): boolean{
		return orgAdminStore.patchOrganizationLoading;
	}
	async submitAddStaff(){
		const orgCopy: OrganizationModel = this.CurrentOrganization.copy();
		orgCopy.staffMembers.push(...this.addStaffFormValue.staffMembers);
		await orgAdminStore.patchOrganization(orgCopy, { inviteStaff: this.addStaffFormValue.sendStaffInvitations });
		await this.loadOrgStaff();
		this.showConfirmAddStaff = false;
		this.resetAddStaffForm();
	}

	TableHeaders: DataTableHeader<any>[] = [
		{ text: 'Name', value: 'name', sortable: false },
		{ text: 'Title', value: 'title', sortable: false },
		{ text: 'Email', value: 'email', sortable: false },
		{ text: 'Mindset', value: 'mindset', sortable: false },
		{ text: '', value: 'actions', sortable: false },
	];
	
	get StaffList(): StaffMember[]{
		return orgAdminStore.staffList;
	}
}
