import { render, staticRenderFns } from "./TeamDashboardV2.vue?vue&type=template&id=ba660b78&scoped=true&"
import script from "./TeamDashboardV2.vue?vue&type=script&lang=ts&"
export * from "./TeamDashboardV2.vue?vue&type=script&lang=ts&"
import style0 from "./TeamDashboardV2.vue?vue&type=style&index=0&id=ba660b78&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba660b78",
  null
  
)

export default component.exports