
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { StringsMixin } from '@/mixins'
import { isNotEmpty } from '@/pipes';

@Component({
	components: {
    },
})
export default class TooltipButton extends Mixins(StringsMixin) {
    @Prop({default: "top"}) pos: string = "top";
    @Prop({default: "primary"}) color: string;
    @Prop() iconColor: string;
    @Prop() textColor: string;
    @Prop() tooltipColor: string;
    @Prop() waitColor: string;
    @Prop() icon: string;
    @Prop({default: "Eat your vegetables"}) tooltip: string;
    @Prop() disabledTooltip: string;
    @Prop() text: string;
    @Prop({type: Boolean}) disabled;
    @Prop({type: Boolean}) large;
    @Prop({type: Boolean}) small;
    @Prop({type: Boolean}) textOnly;
    @Prop({type: Boolean}) noIcon;
    @Prop({type: Boolean}) dense;
    @Prop({type: Boolean}) block;
    @Prop({type: Boolean}) wide;
    @Prop({type: Boolean}) loading;
    @Prop({type: Boolean}) iconAfter;
    @Prop() clickWait: number;

    get IconColor(): string {
      if( this.IsNotEmpty(this.iconColor) ) return this.iconColor;
      if( this.IsNotEmpty(this.text) ) return "white";
      return this.color;
    }
    get TooltipColor(): string{
      if( this.IsNotEmpty(this.tooltipColor) ) return this.tooltipColor;
      return this.color;
    }
    get WaitColor(): string {
      if( this.IsNotEmpty(this.waitColor) ) return this.waitColor;
      if( this.IsNotEmpty(this.text) ) return "white";
      return this.color;
    }

    get IconBefore() {
      if( this.noIcon ) return false;
      if( this.IsEmpty(this.icon) ) return false;
      return !this.iconAfter;
    }
    get IconAfter() {
      if( this.noIcon ) return false;
      if( this.IsEmpty(this.icon) ) return false;
      return this.iconAfter;
    }
    get HasText() {
      return this.IsNotEmpty(this.text);
    }
    get TextStyle() {
      let style = this.dense? "mx-0 px-0" : "mx-4";
      if( this.IsNotEmpty(this.textColor) ) style += ` ${this.textColor}` ;
      return style;
    }
    get LargeIcon(): boolean {
      if( this.HasText ) return false;
      return this.large;
    }
    get SmallIcon(): boolean {
      if( this.HasText ) return false;
      return this.small;
    }
    get Tooltip(): string {
      if( this.disabled ) return this.disabledTooltip;
      return this.tooltip;
    }

    isWaiting: boolean = false;
    async onClick() {
      if( this.disabled ) return;
      this.$emit('click');
      if( isNotEmpty(this.clickWait) ) {
        this.isWaiting = true;
        await new Promise(resolve => setTimeout(resolve, this.clickWait));
        this.isWaiting = false;
      }
    }
    async onClickStop() {
      if( this.disabled ) return;
      this.$emit('click.stop')
    }
}
