
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PublicProfileInfo } from '@/../types/interfaces';
import { athleteApi } from '../../api/AthleteApi';
import { coachApi } from '../../api/CoachApi';
import { AthleteProfileModel } from '../../models/athlete/AthleteProfileModel';
import { CoachProfileModel } from '../../models/coach/CoachProfileModel';
import { UserProfileModel } from '../../models/UserProfileModel';
import { imagesStore } from '../../store';
@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class ProfilePictureProvider extends Vue{
	@Prop({ default: 'div' }) private as: string;

	@Prop() private profileId: string;
	@Prop({ type: Boolean }) private disabled;
	@Prop({ type: Boolean }) private coach;
	@Prop({ type: Boolean }) private athlete;

	hasPicture: boolean = false;
	pictureUrl: string = "";
	firstName: string = "";
	lastName: string = "";

	get SlotProps(): {hasPicture: boolean, pictureUrl: string, fullName: string, profileFound: boolean, loadingProfile: boolean} {
		return {
			hasPicture: this.hasPicture,
			pictureUrl: this.PictureUrl,
			fullName: `${this.firstName} ${this.lastName}`,
			profileFound: this.ProfileFound,
			loadingProfile: this.loadingProfile
		};
	}

	setProfileInfo(url: string, firstName: string, lastName:string): void {
		this.firstName = firstName;
		this.lastName = lastName;
		if(!url || url.length === 0){
			this.pictureUrl = "";
			this.hasPicture = false;
			return;
		}
		this.pictureUrl = url;
		this.hasPicture = true;
	}

	created(): void {
		this.loadProfilePicture();
	}

	loadingProfile = true;
	@Watch('profileId')
	loadProfilePicture(): void{
		this.loadingProfile = true;
		if(!this.disabled && (this.profileId !== undefined && this.profileId !== null)){
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			this.lookupProfilePicture(this.profileId).catch(() => {});
		}
		this.loadingProfile = false;
	}

	get PictureUrl(): string | undefined {
		return imagesStore.GetFromCache(this.profileId);
	}
	get FullName(): string | undefined {
		return `${imagesStore.GetFirstNameFromCache(this.profileId)} ${imagesStore.GetLastNameFromCache(this.profileId)}`;
	}
	get ProfileFound(): boolean{
		return !!this.profileId && !!this.lastName && this.lastName != '';
	}

	async lookupProfilePicture(profileId: string): Promise<void>{
		if( !profileId ) return;

		// check cache
		const url = imagesStore.GetFromCache(profileId);
		if (url) {
			// console.info('cache hit', profileId);
			const firstName = imagesStore.GetFirstNameFromCache(profileId);
			const lastName = imagesStore.GetLastNameFromCache(profileId);
			this.setProfileInfo(url, firstName, lastName);
		} else {
			// console.info('cache miss', profileId);

			let profile: PublicProfileInfo<UserProfileModel>;
			if (this.coach === false && this.athlete === false) {
				profile = await this.lookupCoach(profileId);
				if (profile === undefined) {
					profile = await this.lookupAthlete(profileId);
				}
			} else if (this.coach === true) {
				profile = await this.lookupCoach(profileId);
			} else if (this.athlete === true) {
				profile = await this.lookupAthlete(profileId);
			}
			imagesStore.setCache({id: profileId, url: profile.pictureUrl, firstName: profile.firstName, lastName: profile.lastName});
			//console.info('set cache', profileId, pictureUrl);
			this.setProfileInfo(profile.pictureUrl, profile.firstName, profile.lastName);
		}
	}

	async lookupCoach(profileId: string): Promise<PublicProfileInfo<CoachProfileModel> | undefined>{
		const coach = await coachApi.getProfilePicture(profileId);
		if(coach === null) return undefined;
		return coach;
	}
	async lookupAthlete(profileId: string): Promise<PublicProfileInfo<AthleteProfileModel> | undefined>{
		const athlete = await athleteApi.getProfilePicture(profileId);
		if(athlete === null) return undefined;
		return athlete;
	}

}
