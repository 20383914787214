
import { Component, Mixins } from 'vue-property-decorator';
import { AthleteRoutingMixin, BAIconsMixin, CurrentAthleteMixin, VuetifyMixin } from '@/mixins';
import MindsetSvg from '@/components/svg/MindsetSvg.vue'

import { StartCheckoutParams, AvailableFeatures } from '@/../types/interfaces';
import SubscriptionInfoProvider from '@/components/hoc/SubscriptionInfoProvider.vue';
import ProductInfoProvider from '@/components/hoc/ProductInfoProvider.vue';
import ConsumableCard from '@/components/subscriptions/ConsumableCard.vue';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog.vue';
import { CustomerApi } from '@/api/CustomerApi';
import { AthleteCheckoutPage } from '@/../types/constants/web_client_user.routes';
import * as PlanKeys from '@/../types/constants/plan-keys';
import * as ProductKeys from '@/../types/constants/product-keys';
import { AthleteLineItemMeta } from '@/models/checkoutIntent/AthleteLineItemMeta';
import { notificationStore, userStore } from '@/store';
import { ProductModel } from '@/models/product/ProductModel';
import { SubscriptionModel } from '@/models/stripe/SubscriptionModel';
import { FREE_PLAN } from "@/../types/constants/featureMap";
import { subscriptionApi } from '@/api/SubscriptionApi';
import { CustomerCountry } from '@/../types/enums';
import SubscriptionPlanInfoProvider from '@/components/hoc/SubscriptionPlanInfoProvider.vue';

@Component({
	components: {
		ConsumableCard,
		ConfirmationDialog,
		SubscriptionInfoProvider,
		ProductInfoProvider, 
		MindsetSvg,
		SubscriptionPlanInfoProvider,
	},
})
export default class AthleteSettingsSubscriptionForm extends Mixins(BAIconsMixin, CurrentAthleteMixin, VuetifyMixin, AthleteRoutingMixin){
	PlanKeys = PlanKeys;

	getShowPlanAlertText(subscription: SubscriptionModel | null): boolean{
		if(subscription === null) return true;
		// TODO: Handle letting the user upgrade their plan. For now, hide the plans upgrade modal once they have a plan
		if(subscription.plan.key === PlanKeys.FREE_PLAN_KEY) return true;
		return false;
		// return subscription.plan.key !== PlanKeys.COMPLETE_PLAN_KEY;
	}
	getPlanAlertText(subscription: SubscriptionModel | null): string{
		if(subscription === null || subscription.plan.key === PlanKeys.FREE_PLAN_KEY){
			return "You can upload more videos and even get recruited by upgrading your plan.";
		}
		if(subscription.plan.key === PlanKeys.PREMIUM_PLAN_KEY){
			return "You can have yearly assessments included in your plan if you upgrade.";
		}else if(subscription.plan.key === PlanKeys.COMPLETE_PLAN_KEY){
			return "";
		}
	}

	getConsumableUseText(product: string): string | null{
		if(product === ProductKeys.SPORTS_PERSONALITY_QUESTIONAIRE){
			return "Take it now";
		}
		if(product === ProductKeys.COMPARATIVE_DATA_ADDON){
			return null;
		}
		if(product === ProductKeys.VIDEO_HOURS_5_ADDON){
			return null;
		}
		return "Book it now";
	}

	getConsumableShowCheck(availableFeatures: AvailableFeatures, product: string): boolean{
		if(product === ProductKeys.COMPARATIVE_DATA_ADDON || product === ProductKeys.VIDEO_HOURS_5_ADDON || product === ProductKeys.VIDEO_HOURS_1_ADDON){
			const count = this.getAvailableProductCount(availableFeatures, product);

			// Free plans includes some video minutes; We want to count only non-free minutes
			switch (product) {
			case ProductKeys.VIDEO_HOURS_1_ADDON:
				return count - FREE_PLAN.ATHLETE_VIDEO_MINUTES > 0;
			case ProductKeys.VIDEO_HOURS_5_ADDON:
				return count - FREE_PLAN.ATHLETE_VIDEO_MINUTES > 0;
			default:
				return count > 0;
			}
		}
		return false;
	}

	buyNowButtonLoading: Record<string, boolean> = {
		[ProductKeys.VERIFIED_ASSESSMENT]: false,
		[ProductKeys.SPORTS_PERSONALITY_QUESTIONAIRE]: false,
		[ProductKeys.COMPARATIVE_DATA_ADDON]: false,
		[ProductKeys.VIDEO_HOURS_5_ADDON]: false,
		[ProductKeys.SCHOOL_SEARCH_ADDON]: false,
	};

	async buyNow(product: ProductModel): Promise<void>{
		this.buyNowButtonLoading[product.key] = true;
		const params: StartCheckoutParams<AthleteLineItemMeta> = {
			title: `Purchase a ${product.name}`,
			parentId: this.CurrentAthleteId,
			products:[
				{
					productId: product.stripeProductId,
					quantity: 1,
					meta:{
						athleteId: this.CurrentAthleteId,
					}
				}
			],
			allowCoupons: true,
			automaticTax: true,
		};
		if(product.key === ProductKeys.COMPARATIVE_DATA_ADDON){
			params.title = "Purchase Comparative Data for one year";
		}else if(product.key === ProductKeys.VIDEO_HOURS_5_ADDON){
			params.title = "Purchase an additional 5 hours of video storage";
		}
		const intent = await CustomerApi.createCheckoutIntent('athlete', this.CurrentAthleteId, params);
		this.$router.push({
			name: AthleteCheckoutPage,
			params:{
				...this.$route.params,
				checkoutIntentId: intent.id
			}
		});
		this.buyNowButtonLoading[product.key] = false;
		return;
	}

	async bookNow(product: ProductModel): Promise<void>{
		if(product.key === ProductKeys.VERIFIED_ASSESSMENT){
			window.open('https://info.speedtraining.ca/batestingday', '_blank');
		}
	}

	getAvailableProductCount(features: AvailableFeatures, productKey: string): number{
		return SubscriptionModel.getAvailableProductCount(features, productKey);
	}

	get CustomerCountry(): CustomerCountry {
		return userStore.CurrentUserCountry;
	}

	unsubscribePlan: string;
	showUnsubscribeDialog: boolean = false;
	showSubscriptionCanceledDialog: boolean = false;
	async startUnsubscribe(planId: string) {
		this.unsubscribePlan = planId;
		this.showUnsubscribeDialog = true;
	}
	async onConfirmUnsubscribe() {
		try {
			await subscriptionApi.unsubscribe('athlete', this.CurrentAthleteId, this.unsubscribePlan);
			notificationStore.snackbarShowSuccess(`Your subscription has been canceled`);
			notificationStore.pushLocalNotificationMessage(`Your subscription has been canceled`);
			this.showSubscriptionCanceledDialog = true;
		} catch(e) {
			notificationStore.snackbarShowError(`Error unsubscribing: ${e}`)
		}
		this.showUnsubscribeDialog = false;
	}
}
