// CoachRecruiting.store.ts: CoachRecruitingStore

import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { TargetedSharingModel } from '@/models/targetedSharing/TargetedSharingModel';
import { RecipientTargetedSharingApi } from '@/api/RecipientTargetedSharingApi';
import { AthleteTargetedSharingApi } from '@/api/AthleteTargetedSharingApi';
import { PaginatedResponse, QueryOptions, RepositoryQuery } from '@/../types/interfaces';
import { RecruitingResponse } from '@/../types/enums/recruiting-response';
import { RecruitingProfileModel } from '@/models/athlete/RecruitingProfileModel';
import { AthleteProfileModel } from '@/models';
import { VideoModel } from '@/models/video/VideoModel';
import { VideoClipModel } from '@/models/video/VideoClipModel';
import { notificationStore } from '@/store'
import { coachApi } from '@/api/CoachApi';

const Mutations = {
	QUERY_RECRUITING_DATA: 'QUERY_RECRUITING_DATA',
	QUERY_RECRUITING_DATA_SUCCESS: 'QUERY_RECRUITING_DATA_SUCCESS',
	QUERY_RECRUITING_DATA_FAILURE: 'QUERY_RECRUITING_DATA_FAILURE',

	SET_RESPONSE: 'SET_RESPONSE',
	SET_RESPONSE_SUCCESS: 'SET_RESPONSE_SUCCESS',
	SET_RESPONSE_FAILURE: 'SET_RESPONSE_FAILURE',

	SET_COACHNOTES: 'SET_COACHNOTES',
	SET_COACHNOTES_SUCCESS: 'SET_COACHNOTES_SUCCESS',
	SET_COACHNOTES_FAILURE: 'SET_COACHNOTES_FAILURE',

	ADD_COACH_VIEW: 'ADD_COACH_VIEW',
	ADD_COACH_VIEW_SUCCESS: 'ADD_COACH_VIEW_SUCCESS',
	ADD_COACH_VIEW_FAILURE: 'ADD_COACH_VIEW_FAILURE',
};

const name = 'CoachRecruitingStore';
if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class CoachRecruitingModule extends VuexModule {

	@Action({rawError: true})
	async getVideosIfShareExists({ coachId, athleteId }: { coachId: string, athleteId: string }): Promise<VideoModel[] | null>{
		const share = await this.findShareForAthleteId({ coachId, athleteId });
		if(share === null) return null;
		return await (new RecipientTargetedSharingApi(coachId)).getAthleteProfileVideos(share.id);
	}
	@Action({rawError: true})
	async getVideoClipsIfShareExists({ coachId, athleteId }: { coachId: string, athleteId: string }): Promise<VideoClipModel[] | null>{
		const share = await this.findShareForAthleteId({ coachId, athleteId });
		if(share === null) return null;
		return await (new RecipientTargetedSharingApi(coachId)).getAthleteProfileVideoClips(share.id);
	}
	@Action({rawError: true})
	async getRecruitingProfileIfShareExists({ coachId, athleteId }: { coachId: string, athleteId: string }): Promise<RecruitingProfileModel | null>{
		const share = await this.findShareForAthleteId({ coachId, athleteId });
		if(share === null) return null;
		return await (new RecipientTargetedSharingApi(coachId)).getRecruitingProfile(share.id);
	}
	@Action({rawError: true})
	async getAthleteProfileIfShareExists({ coachId, athleteId }: { coachId: string, athleteId: string }): Promise<AthleteProfileModel | null>{
		const share = await this.findShareForAthleteId({ coachId, athleteId });
		if( !share ) return null;

		const api = new RecipientTargetedSharingApi(coachId);
		const athleteProfile = await api.getAthleteProfile(share.id);
		return athleteProfile;
	}
	@Action({rawError: true})
	async findShareForAthleteId({ coachId, athleteId }: { coachId: string, athleteId: string }): Promise<TargetedSharingModel | null>{
		const searchResult = await (new RecipientTargetedSharingApi(coachId)).queryAll({
			$match: {
				parentId: athleteId
			}
		},{
			limitPerPage: 1,
		});
		const [ share ] = searchResult.docs;
		if (share === undefined){
			return null;
		}
		return new TargetedSharingModel().load(share);
	}

	queryRecruitingDataInitialized: boolean = false;
	queryRecruitingDataLoading: boolean = false;
	totalSharingRecords: number = 0;
	sharingRecords: TargetedSharingModel[] = [];
	totalLikedRecords: number = 0;
	likedRecords: TargetedSharingModel[] = [];
	totalUnseenRecords: number = 0;
	unseenRecords: TargetedSharingModel[] = [];
	@Action({
		rawError: true,
	}) async queryRecruitingData({ coachId, query, options }: { coachId: string, query: RepositoryQuery<TargetedSharingModel>, options: QueryOptions  }): Promise<void> {
		this.context.commit(Mutations.QUERY_RECRUITING_DATA)
		try {
			const api = new RecipientTargetedSharingApi(coachId);
			const shareData = await api.queryAll(query,options)
			this.context.commit(Mutations.QUERY_RECRUITING_DATA_SUCCESS, { shareData } );
		} catch (e) {
			notificationStore.pushSnackbarError({message: `Failed loading recruiting data: ${e}`});
			this.context.commit(Mutations.QUERY_RECRUITING_DATA_FAILURE, e);
		}
	}
	@Mutation [Mutations.QUERY_RECRUITING_DATA](): void {
		this.queryRecruitingDataLoading = true;
	}

	@Mutation [Mutations.QUERY_RECRUITING_DATA_SUCCESS]({
		shareData,
	}: {
		shareData: PaginatedResponse<TargetedSharingModel>,
	} ): void {
		this.sharingRecords = shareData.docs.filter(d => d.parentId != 'undefined');
		this.totalSharingRecords = shareData.total;
		this.queryRecruitingDataLoading = false;
		this.queryRecruitingDataInitialized = true;
	}

	@Mutation [Mutations.QUERY_RECRUITING_DATA_FAILURE](error: any): void {
		this.queryRecruitingDataLoading = false;
	}

	@Action({ rawError: true})
	async setCoachArchive({ coachId, id, archive}: {coachId: string, id: string, archive: boolean}) {
		try {
			const api = new RecipientTargetedSharingApi(coachId);
			await api.setArchiveCoach(id, archive);
		} catch(e) {
			notificationStore.pushSnackbarError({message: `Failed setting archive status: ${e}`});
		}
	}

	setResponseLoading: boolean = false;
	@Action({
		rawError: true,
	}) async setResponse({ coachId, id, response }: { coachId: string, id: string, response: RecruitingResponse }): Promise<void> {
		this.context.commit(Mutations.SET_RESPONSE)
		try {
			const api = new RecipientTargetedSharingApi(coachId);
			const share = await api.setResponse(id, response);
			this.context.commit(Mutations.SET_RESPONSE_SUCCESS, { share } );
		} catch (e) {
			notificationStore.pushSnackbarError({message: `Failed setting response: ${e}`});
			this.context.commit(Mutations.SET_RESPONSE_FAILURE, e);
		}
	}
	@Mutation [Mutations.SET_RESPONSE](): void {
		this.setResponseLoading = true;
	}

	@Mutation [Mutations.SET_RESPONSE_SUCCESS](): void {
		this.setResponseLoading = false;
	}

	@Mutation [Mutations.SET_RESPONSE_FAILURE](error: any): void {
		this.setResponseLoading = false;
	}

	setCoachNotesLoading: boolean = false;
	@Action({
		rawError: true,
	}) async setCoachNotes({ coachId, id, notes }: { coachId: string, id: string, notes: string }): Promise<void> {
		this.context.commit(Mutations.SET_COACHNOTES)
		try {
			const api = new RecipientTargetedSharingApi(coachId);
			const share = await api.setCoachNotes(id, notes);
			this.context.commit(Mutations.SET_COACHNOTES_SUCCESS, { share } );
		} catch (e) {
			notificationStore.pushSnackbarError({message: `Failed setting response: ${e}`});
			this.context.commit(Mutations.SET_COACHNOTES_FAILURE, e);
		}
	}
	@Mutation [Mutations.SET_COACHNOTES](): void {
		this.setCoachNotesLoading = true;
	}

	@Mutation [Mutations.SET_COACHNOTES_SUCCESS](): void {
		this.setCoachNotesLoading = false;
	}

	@Mutation [Mutations.SET_COACHNOTES_FAILURE](error: any): void {
		this.setCoachNotesLoading = false;
	}

	addingCoachView: boolean = false;
	@Action({rawError: true})
	async addCoachView({athleteId, coachId}: {athleteId: string, coachId: string}): Promise<TargetedSharingModel> {
		this.context.commit(Mutations.ADD_COACH_VIEW)
		try {
			const coach = await coachApi.findById(coachId);
			if( !coach ) return undefined;

			const newView: TargetedSharingModel = new TargetedSharingModel;
			newView.date = new Date();
			newView.parentId = athleteId;
			newView.email = coach.email;
			newView.seen = true;
			newView.seenOnce = true;
			newView.found = true;
			newView.recipientData = {
				coachProfileId: coachId,
				date: new Date(),
				response: RecruitingResponse.Seen,
			}
			const api = new AthleteTargetedSharingApi(newView.parentId);
			const shared = api.save(newView)
			this.context.commit(Mutations.ADD_COACH_VIEW_SUCCESS, {shared});
			return shared;
		} catch(e) {
			this.context.commit(Mutations.ADD_COACH_VIEW_FAILURE, e);
			return undefined;
		}
	}
	@Mutation [Mutations.ADD_COACH_VIEW](): void {
		this.addingCoachView = true;
	}

	@Mutation [Mutations.ADD_COACH_VIEW_SUCCESS](): void {
		this.addingCoachView = false;
	}

	@Mutation [Mutations.ADD_COACH_VIEW_FAILURE](error: any): void {
		this.addingCoachView = false;
		notificationStore.pushSnackbarError({message: `Unable to add coach view: ${error}`});
	}

}