
import { Component, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, MyAthleteMixin, PaginatedTableMixin, StringsMixin, VuetifyMixin } from '@/mixins';
import { AthleteAssessmentDataModel } from '@/models'
import { AthleteMetric } from '@/../types/enums';
import AthleteVerifiedAssessmentsCard from "@/components/profile/athlete/AthleteVerifiedAssessmentsCard.vue";
import { AthleteComparativeDataSummary } from "@/../types/constants/web_client_user.routes";
import { DataTableHeader } from 'vuetify';
import { RepositoryQuery, QueryOptions, PaginatedResponse } from '@/../types/interfaces';
import { athleteAssessmentDataApi } from '@/api/AthleteAssessmentDataApi';

@Component({
	components:{
		AthleteVerifiedAssessmentsCard,
	}
})
export default class AthleteVerifiedAssessmentsHistory extends Mixins(VuetifyMixin, StringsMixin, PaginatedTableMixin, BAIconsMixin, MyAthleteMixin) {
	search: string = '';

	mounted(): void {
		this.tableOptions.sortBy = ['assessmentDate'];
		this.tableOptions.sortDesc = [true];

		this.localForagePersistFields = [['search', ''],['tableOptions.page', 1],['tableOptions.itemsPerPage', 5]];
	}

	get TableHeaders(): Array<DataTableHeader<any>> {
		let headers: Array<DataTableHeader<any>> = [
			{ text: 'Date', value: 'date', sortable: false },
			{ text: 'Acceleration', value: 'acceleration', sortable: false },
			{ text: 'Speed', value: 'speed', sortable: false },
			{ text: 'Agility', value: 'agility', sortable: false },
			{ text: 'Power', value: 'power', sortable: false },
			{ text: 'Recovery', value: 'recovery', sortable: false },
		];
		if( this.IsLargeScreen ) {
			headers.push({text: '', value: 'actions', sortable: false });
			headers.push({text: '', value: 'data-table-expand', sortable: false});
		}
		return headers;
	}

	assessmentDatasLoading: boolean = false;
	assessmentDatasLoaded: boolean = false;
	assessmentDatas: Array<AthleteAssessmentDataModel>;
	assessmentDatasCount: number;
	async loadTable() {
		this.assessmentDatasLoading = true;
		this.assessmentDatasLoaded = false;
		const query: RepositoryQuery<AthleteAssessmentDataModel> = this.TableQuery<AthleteAssessmentDataModel>(this.search, ['athleteId', 'tags']);;
		query.$match = {...query.$match, ...{ athleteId: this.TheAthleteId }};
		const options: QueryOptions = this.TableQueryOptions;
		const response: PaginatedResponse<AthleteAssessmentDataModel> = await athleteAssessmentDataApi.queryAll(query, options);
		this.assessmentDatas = response.docs;
		this.assessmentDatasCount = response.count;
		this.assessmentDatasLoaded = true;
		this.assessmentDatasLoading = false;
	}

	get TableLoading(): boolean {
		return this.assessmentDatasLoading;
	}

	get PageLoading(): boolean {
		return this.TableLoading || this.assessmentDatasLoading;
	}

	get TotalItems(): number {
		return this.assessmentDatasCount;
	}

	onViewComparativeData(metric: AthleteMetric) {
		// view the comparative data for the specified metric for this athlete
		this.$router.push({
			name: AthleteComparativeDataSummary,
			params:{
				metric,
				athleteId: this.athleteId,
			}
		});
		// go to the top of the page
		this.$vuetify.goTo(0);
	}
}
