import store from '../store';
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators';
import { CoachProfileModel } from '../../models/coach/CoachProfileModel'
import { coachApi } from '@/api/CoachApi';

const Mutations = {
	GET_PROFILE: 'GET_PROFILE',
	GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
	GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

	SET_PROFILE: 'SET_PROFILE',

	SET_PROFILE_PICTURE: 'SET_PROFILE_PICTURE',
	SET_PROFILE_PICTURE_SUCCESS: 'SET_PROFILE_PICTURE_SUCCESS',
	SET_PROFILE_PICTURE_FAILURE: 'SET_PROFILE_PICTURE_FAILURE',
}

const name = 'CoachProfileStore';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class CoachProfileModule extends VuexModule {

	initSelectorDataLoading: boolean = true;

	loading: boolean = false;

	get CoachProfileIsReady() {
		return this.coachProfileInitialized && !this.loading;
	}
	
	coachProfileInitialized: boolean = false;
	coachProfile: CoachProfileModel | null = null;

	@Action({
		rawError: true,
	}) async getCoachProfile(id: string) {
		if( !id ) return;
		
		this.context.commit(Mutations.GET_PROFILE);
		try {
			const coachProfile = await coachApi.findById(id);
			this.context.commit(Mutations.GET_PROFILE_SUCCESS, coachProfile)
		} catch (e) {
			console.error("Failed to Create Coach Resume", e);
			this.context.commit(Mutations.GET_PROFILE_FAILURE, e);
		}
	}

	@Mutation [Mutations.GET_PROFILE]() {
		this.loading = true;
	}
	@Mutation [Mutations.GET_PROFILE_SUCCESS](coachProfile: CoachProfileModel) {
		this.coachProfile = coachProfile;
		this.loading = false;
		this.coachProfileInitialized = true;
	}
	@Mutation [Mutations.GET_PROFILE_FAILURE](error: any) {
		this.loading = false;
	}
	@Mutation [Mutations.SET_PROFILE](coachProfile: CoachProfileModel) {
		this.coachProfile = coachProfile;
		this.coachProfileInitialized = true;
	}

	setCoachProfilePictureLoading: boolean = false;
	@Action({
		rawError: true,
	}) async setCoachProfilePicture({ coachId, pictureUrl }: { coachId: string, pictureUrl: string }) {
		this.context.commit(Mutations.SET_PROFILE_PICTURE);
		try {
			if(!this.coachProfileInitialized) throw new Error("Coach Profile is not ready");
			await coachApi.patch({
				id: coachId,
				pictureUrl,
			});
			this.context.commit(Mutations.SET_PROFILE_PICTURE_SUCCESS, pictureUrl);
		} catch (e) {
			console.error("Failed to update Coach Picture Url", e);
			this.context.commit(Mutations.SET_PROFILE_PICTURE_FAILURE, e);
		}
	}

	@Mutation [Mutations.SET_PROFILE_PICTURE]() {
		this.setCoachProfilePictureLoading = true;
	}
	@Mutation [Mutations.SET_PROFILE_PICTURE_SUCCESS](pictureUrl: string) {
		this.coachProfile.pictureUrl = pictureUrl;
		this.setCoachProfilePictureLoading = false;
	}
	@Mutation [Mutations.SET_PROFILE_PICTURE_FAILURE](error: any) {
		this.setCoachProfilePictureLoading = false;
	}

	@MutationAction
	async patchCoachProfile(coachProfile: Partial<CoachProfileModel>): Promise<{ coachProfile: CoachProfileModel }> {
		const savedProfile = new CoachProfileModel().load(await coachApi.patch(coachProfile));

		return { coachProfile: savedProfile };
	}
}
