import { AthleteMetric } from '@/../types/enums';
export function getMetricUnitLabel(value: AthleteMetric, selfAssessment: boolean = false, derived: boolean = false): string {
	switch (value) {
	case AthleteMetric.Acceleration:
		return derived? 'Acceleration over 10M' : '10M Sprint Time';
	case AthleteMetric.Speed:
		return derived? 'Speed over 20-35M' : '20-35M Split Time';
	case AthleteMetric.Agility:
		return selfAssessment ? 'Time' : derived? 'Agility Index' : 'Counter Jump Height';
	case AthleteMetric.Power:
		return derived? 'Power' : 'Vertical Jump Height';
	case AthleteMetric.Recovery:
		return derived? 'Recovery' : 'YoYo Test';
	default:
		return '';
	}
}
