import { Component, Vue } from 'vue-property-decorator';
import { coachProfile as coachProfileStore } from '../store';
import { CoachProfileModel } from '../models/coach/CoachProfileModel';
import { CoachResumeModel } from '@/models/coach/CoachResumeModel';
import { Auth0Plugin } from '@/auth/Auth0';
import { TeamModel } from '@/models';
import { isEmpty, isNotEmpty } from '@/pipes';

/**
 * Provides computed properties: 
 * - MyCoachId
 * - MyCoachProfile
 * - MyCoachResume
 */
@Component
export class MyCoachMixin extends Vue {
	reloadMyCoach: boolean = true;
	$auth: Auth0Plugin;
	get MyCoachIsReady(): boolean{
		return !coachProfileStore.loading && coachProfileStore.coachProfileInitialized;
	}

	get MyCoachId(): string {
		if (this.$auth.user && this.$auth.user.coachId){
			return this.$auth.user.coachId;
		}
		return undefined;
	}
	/** Alias for MyCoachId */
	get MyCoachProfileId(): string {
		if (this.$auth.user && this.$auth.user.coachId){
			return this.$auth.user.coachId;
		}
		return undefined;
	}

	get MyCoachProfile(): CoachProfileModel {
		if(this.MyCoachIsReady){
			return coachProfileStore.coachProfile;
		}
		return undefined;
	}

	get MyCoachResume(): CoachResumeModel {
		if(this.MyCoachIsReady){
			return coachProfileStore.coachProfile.resume;
		}
		return undefined;
	}

	async moveCoachTeam(from: number, to: number) {
		// coach profile does not have a team list
		if( isEmpty(coachProfileStore.coachProfile.teams) ) return;
		if( from === to ) return;

		if( from < 0 || to < 0 || from >= this.MyCoachProfile.teams.length || to >= this.MyCoachProfile.teams.length ) return;
		const newTeamsList = [...this.MyCoachProfile.teams];
		const [team] = newTeamsList.splice(from, 1);
		newTeamsList.splice(to, 0, team);
		this.MyCoachProfile.teams = newTeamsList;

		await coachProfileStore.patchCoachProfile({
			id: coachProfileStore.coachProfile.id,
			teams: coachProfileStore.coachProfile.teams,
		});
	}

	async UpdateCoachTeams(teams: Array<TeamModel>) {
		// coach has no teams
		if( isEmpty(teams) ) return;

		// coach profile already has teams list
		if( isNotEmpty(coachProfileStore.coachProfile.teams) ) return;

		coachProfileStore.coachProfile.teams = teams.map(t => t.id);
		await coachProfileStore.patchCoachProfile(coachProfileStore.coachProfile);
	}

	created(){
		if( !this.MyCoachId ) return;
		
		if( this.reloadMyCoach || !coachProfileStore.coachProfileInitialized ){
			coachProfileStore.getCoachProfile(this.MyCoachId);
		}
	}

}
