import { isNotEmpty } from '@/pipes';
import { UserProfileModel } from '../UserProfileModel';
import { CoachResumeModel } from './CoachResumeModel'

export class CoachProfileModel extends UserProfileModel {
	sports: string[] = [];
	country: string = "";
	resume?: CoachResumeModel;
	teams: string[] = [];			// ids of coach teams used for display order

	load(obj: Partial<CoachProfileModel>): this{
		Object.assign(this, obj);
		if (obj['resume'] !== undefined){
			this.resume = new CoachResumeModel().load(obj['resume']);
		} 
		else {
			this.resume = new CoachResumeModel();
		}
		return this;
	}

	get SportName(): string {
		if( !!this.sports && this.sports.length > 0 ) return this.sports[0];
		return "Sport";
	}
	get HasTeamsOrder(): boolean {
		return isNotEmpty(this.teams);
	}
}