
import { Component, Mixins } from 'vue-property-decorator';
import TeamList from './TeamList.vue';
import { BAIconsMixin, CoachRoutingMixin } from '@/mixins';

@Component({
	components: { 
		TeamList
	},
})
export default class TeamManagement extends Mixins(BAIconsMixin, CoachRoutingMixin){
}

