
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { BAIconsMixin, StringsMixin } from '@/mixins';
import { AthleteProfileModel } from '@/models';
import ShareableProfileInfo from "@/components/profile/recruiting/ShareableProfileInfo.vue"
import RecruitingProfileInfo from "@/components/profile/recruiting/RecruitingProfileInfo.vue";

@Component({
    components: {
        ShareableProfileInfo,
        RecruitingProfileInfo,
    }
})
export default class AthleteEducationCard extends Mixins(StringsMixin, BAIconsMixin) {
    @Prop() athleteProfile: AthleteProfileModel;
    @Prop() showHelp: boolean;
    @Prop() public: boolean;
}
