
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { PlayerOnTeam, TeamModel } from '@/models/team';
import { BAIconsMixin, StringsMixin, VuetifyMixin } from '@/mixins';

@Component({
	components: { },
})
export default class TeamMiniCard extends Mixins(StringsMixin, BAIconsMixin, VuetifyMixin){
	@Prop({ required: true }) team: TeamModel;
	@Prop({ type: String }) athleteId;
	@Prop({ default: 50 }) size: number;
	@Prop({ type: Boolean }) hideName;
	@Prop({ type: Boolean }) hideLocation;
	@Prop({ type: Boolean }) noEditNumber;
	/** v-text-field class to align with other inputs */
	@Prop({ default: true, type: Boolean }) useInputClass: boolean;
	@Prop({ default: 'align-start', type: String }) alignClass: string;
	@Prop({ type: Boolean }) showDetails;
	@Prop({ type: Boolean }) showJersey;
	@Prop({ type: Boolean }) showStatus;
	@Prop({ type: Boolean }) showExpanded;
	@Prop({ type: Boolean }) editable;
	@Prop({ type: Boolean }) dense;
	@Prop({ type: Boolean }) allowSetCurrentTeam;

	ready: boolean = false;
	expanded: boolean = false;
	changesPending: boolean = false;
	athleteOnTeam: PlayerOnTeam;

	mounted() {
		this.ready = false;
		if( this.IsEmpty(this.athleteId) || !this.HasTeam ) { 
			this.ready = true;
			return;
		}

		this.athleteOnTeam = this.team.players.find(p => p.athleteId === this.athleteId);
		this.eligible = this.athleteOnTeam? this.athleteOnTeam.eligibleToPlay? 'Eligible':'Suspended' : 'Not on team';
		this.expanded = this.showExpanded;
		this.ready = true;
	}

	get HasTeam(): boolean {
		return this.IsNotEmpty(this.team);
	}
	get ShowJersey(): boolean {
		if( !this.HasTeam ) return false;
		return this.showJersey;
	}

	SizeForScreen(size: number): number {
		if( this.IsMobile ) return size * 0.75;
		return size;
	}
	get AvatarSize(): number {
		return this.SizeForScreen(this.size);
	}
	get JerseySize(): number {
		return this.SizeForScreen(this.size / 5);
	}

	eligible: string = "Eligible";
	@Watch('eligible')
	async updateEligibility() {
		if( this.IsEmpty( this.athleteOnTeam ) ) return;
		this.athleteOnTeam.eligibleToPlay = (this.eligible === 'Eligible');
	}

	async onSetInjuryStatus() {
		this.athleteOnTeam.injured = !this.athleteOnTeam.injured;
		this.$emit('setInjured', this.athleteOnTeam.injured);
	}
	async onSetEligibilityStatus() {
		this.athleteOnTeam.eligibleToPlay = !this.athleteOnTeam.eligibleToPlay;
		this.$emit('setEligible', this.team.id, this.athleteOnTeam.eligibleToPlay);
	}

	isAthleteInfoChanging: boolean = false;
	async onAthleteInfoChange() {
		this.isAthleteInfoChanging = true;
		this.$emit('changeAthleteInfo');
		await new Promise(r => setTimeout(r, 500));
		this.isAthleteInfoChanging = false;
	}
}
