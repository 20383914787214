
import { Component, Prop, Mixins } from 'vue-property-decorator';
import BestAthletesLogo from '../ui/BestAthletesLogo.vue';
import {AppHostnameMixin, BAIconsMixin, OrgAdminRoutingMixin, OrgResolverMixin, StringsMixin, VuetifyMixin} from '@/mixins';
import SideNavSectionTitle from './SideNavSectionTitle.vue';
import { TeamModel } from '@/models';
import { EventBus } from '@/eventBus';

@Component({
	components: {
		BestAthletesLogo,
		SideNavSectionTitle,
	},
})
export default class OrgAdminSideNav extends Mixins(BAIconsMixin, AppHostnameMixin, StringsMixin, VuetifyMixin, OrgAdminRoutingMixin, OrgResolverMixin){
	@Prop({ default: true, type: Boolean }) value: boolean;
	mini = false;
	isTeamsExpanded: boolean = true;
	isOrgExpanded: boolean = false;

	created() {
		EventBus.$on('orgTeamsLoaded', this.onTeamsLoaded);
	}
	async mounted(){
		await this.loadOrganizations();
		await this.updateTeams(this.OrganizationId);
	}

	get IsOrgOpen(): boolean {
		return this.IsNotEmpty(this.OrganizationId);
	}

	get OrganizationId(): string {
		return this.$route.params.organizationId;
	}

	async organizationLoadComplete() {
		super.organizationLoadComplete();
		this.updateTeams(this.OrganizationId);
	}
	async updateTeams(organizationId: string) {
		await this.loadOrgTeams(organizationId);
		this.isTeamsExpanded = this.IsNotEmpty(organizationId);
		this.isOrgExpanded = false;
	}
	onTeamsLoaded(organizationId: string) {
		this.updateTeams(organizationId);
	}

	async onClickDashboard() {
		this.gotoOrgsDashboard();
		this.isTeamsExpanded = false;
		this.isOrgExpanded = false;
	}
	async onClickOrgSettings(orgId: string) {
		this.gotoOrgSettings(orgId)
	}
	async onClickOrganization(organizationId: string) {
		await this.updateTeams(organizationId)
		await this.gotoOrganization(organizationId);
	}
	async onClickTeam(team: TeamModel) {
		this.gotoOrgTeam(team.id);
	}
	async onClickAthletes() {
		this.gotoOrgAthletes(this.OrganizationId);
	}
	async onClickStaff() {
		this.gotoOrgStaff(this.OrganizationId);
	}
	async onClickCreateTeam() {
		this.gotoTeamCreate();
	}

	get SectionTitleColor(): string {
		return this.getColor(this.IsOrgOpen? '' : 'baColorGray1');
	}
}
