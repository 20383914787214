import Vue from 'vue';
import store from '../store';
import { Module, VuexModule, Mutation, Action,  } from 'vuex-module-decorators';

const Mutations = {
	SET_CACHE: 'SET_CACHE'
};

const name = 'ImagesStore';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class ImagesModule extends VuexModule {

	firstNameCache: Record<string, string> = {};
	lastNameCache: Record<string, string> = {};
	cache: Record<string, string> = {};

	get GetFromCache() {
		return (id: string): string | undefined => this.cache[id] !== undefined ? this.cache[id] : undefined;
	}
	get GetFirstNameFromCache() {
		return (id: string): string | undefined => this.firstNameCache[id] !== undefined ? this.firstNameCache[id] : undefined;
	}
	get GetLastNameFromCache() {
		return (id: string): string | undefined => this.lastNameCache[id] !== undefined ? this.lastNameCache[id] : undefined;
	}

	@Action({
		rawError: true,
	}) async setCache({ id, url, firstName, lastName }: { id: string, url: string, firstName?: string, lastName?: string }): Promise<void> {
		this.context.commit(Mutations.SET_CACHE, { id: id, url: url, firstName, lastName});
	}

	@Mutation [Mutations.SET_CACHE]({ id, url, firstName, lastName }: { id: string, url: string, firstName?: string, lastName?: string }): void {
		Vue.set(this.cache, id, url);
		if( firstName ) Vue.set(this.firstNameCache, id, firstName);
		if( lastName ) Vue.set(this.lastNameCache, id, lastName);
	}
}
