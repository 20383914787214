import { userStore } from '@/store';
import { Route, NavigationGuardNext } from 'vue-router';
import { getInstance } from "./auth0Plug";

export function hasPublicAthleteRoute(path: string): boolean {
	const athletePublics = [
		'partners',
		'baevent',
		'club',
	];

	for( const p of athletePublics ) {
		if( path.includes(p) ) return true;
	}
	return false;
}

export async function authGuard(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
	const profileId = to.query.u ?? null;
	const authService = getInstance();

	const fn = async () => {
		if( userStore.IsReady && !userStore.userProfilesInitialized ) {
			await userStore.loadUserProfiles();
		}
		
		if (profileId !== null && !Array.isArray(profileId)) {
			await userStore.useProfile({
				profileId,
			});
		}

		// if the user is authenticated, continue with the route
		if (authService.isAuthenticated) {
			return next();
		}

		// if this is an accepted public route, go to the athlete public route
		if( hasPublicAthleteRoute(to.path) ) {
			const path = `public-a${to.path}`;
			return next({ path });
		}
		// Otherwise, log in
		authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
	};

	// If loading has already finished, check our auth state using `fn()`
	if (!authService.loading) {
		return fn();
	}

	// Watch for the loading property to change before we check isAuthenticated
	authService.$watch("loading", (loading: boolean) => {
		// console.log("Loading Finished")
		if (loading === false) {
			return fn();
		}
	});
}