
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin, StringsMixin, FormRulesMixin } from '@/mixins';
import { TeamModel, PlayerOnTeam } from '@/models/team';
import { InvitationStatus } from '@/../types/enums';
import { InvitePlayerFormValue } from '@/../types/interfaces';
import { teamDashboardStore } from '@/store';
import { teamApi } from '@/api/TeamApi';

@Component
export default class InvitePlayerDialog extends Mixins(FormRulesMixin, VuetifyMixin, BAIconsMixin, StringsMixin){
    @Prop({ required: true, default: undefined }) value: PlayerOnTeam;
    @Prop({ required: true, default: undefined }) team: TeamModel;
    @Prop({ type: Boolean }) disabled;
    @Prop({ type: Boolean }) noIcon;
    @Prop({ type: Boolean }) large;
    @Prop({ type: Boolean }) dense;
	@Prop({ type: Boolean }) hideIfJoined;
    @Prop() text: string;


	invitingPlayer: boolean = false;
	invitePlayer: PlayerOnTeam;
	onStartInvitePlayer() {
        // make a copy of the player to invite
		this.invitePlayer = {...this.value};
		this.invitingPlayer = true;
	}
	onCancelInvitePlayer() {
        // player has not been changed, reset state
        this.invitePlayer = undefined;
		this.invitingPlayer = false;
        this.$emit("cancel");
	}
	async onAcceptInvitePlayer() {
        // copy the contents of the invited player onto the original player
        for( const key in this.invitePlayer ) {
            if( this.invitePlayer.hasOwnProperty(key) ) {
                this.value[key] = this.invitePlayer[key];
            }
        }

		const player: InvitePlayerFormValue = {...this.invitePlayer};
		if( this.IsNotEmpty(this.value.email) ) {
			await teamDashboardStore.invitePlayerByEmail({
				teamId: this.team.id,
				email: this.value.email,
				player,
			});
		} else {
			await teamDashboardStore.addPlayerToTeam({
				teamId: this.team.id,
				player
			});
		}

        // update the player information to persistent storage
		await this.patchPlayer(this.value.id, this.team.id, this.value);
        this.$emit("input", this.value);

        // reset state
		this.invitingPlayer = false;
        this.invitePlayer = undefined;
	}

	get IsPlayerOnTeam() {
		if( this.IsEmpty(this.value?.athleteId) ) return false;
		if( this.IsEmpty(this.team?.players) ) return false;

		const playerOnTeam = this.team.players.find(p => p.athleteId === this.value.athleteId);
		return this.IsNotEmpty(playerOnTeam);
	}
	get IsResend(){
		return this.IsNotEmpty(this.value?.athleteId) && (this.value?.invitationStatus != InvitationStatus.None);
	}
	get PlayerFullName(): string {
		return this.FullName(this.value?.firstName, this.value?.lastName);
	}
	get IsInviteLoading(){
		return teamDashboardStore.manualInviteLoading;
	}

    async patchPlayer(playerId: string, teamId: string, playerPatch: Partial<PlayerOnTeam>): Promise<PlayerOnTeam> {
        return await teamApi.patchPlayer( {teamId, playerId, playerPatch });
	}
}
