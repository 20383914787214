import { isNotEmpty } from '@/pipes';
import { AccessControlledModel } from './AccessControlledModel';

export class UserProfileModel extends AccessControlledModel {
	shortId: string;
	authIds: string[] = [];
	firstName: string = "";
	lastName: string = "";
	email: string = "";
	phoneNumber: string = "";
	pictureUrl: string = "";
	completedProfile: boolean = false;
	athleteId?: string | null;
	autocompleteDiscoverable: boolean = true;
	fromOrganization: string = "";				// Self reported Club/Org name from onboarding

	constructor(init?: Partial<UserProfileModel>) {
		super();
		Object.assign(this, init);
	}
	
	// Combines firstName and lastName
	get FullName(): string {
		return isNotEmpty(this.lastName)? `${this.firstName} ${this.lastName}` : this.firstName;
	}
}
